import React from 'react';
import PropTypes from 'prop-types';

import {TrainingShelfWrapper, RowWrapper} from './styles';
import {Column, Container, Row} from '../../../layout/Grid';
import Teaser from '../Teaser';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';
import {matchMedia} from '../../../../styles/media';

const TrainingShelf = ({trainings}) => {
    return (
        <TrainingShelfWrapper>
            <ResponsiveSwitch>
                {(match) => {
                    if (match.sm) {
                        return <Container>{renderRows(trainings)}</Container>;
                    }
                    return (
                        <Container>
                            {trainings.map((training) => {
                                return (
                                    <RowWrapper key={training.id}>
                                        <Row>
                                            <Column xs={12}>
                                                <Teaser
                                                    title={training.title}
                                                    type={training.type.name}
                                                    image={training.image}
                                                    points={training.points}
                                                    duration={training.duration}
                                                    completed={
                                                        training.completed
                                                    }
                                                    route={training.route}
                                                    isReleased={
                                                        training.isReleased
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                    </RowWrapper>
                                );
                            })}
                        </Container>
                    );
                }}
            </ResponsiveSwitch>
        </TrainingShelfWrapper>
    );
};

function renderRows(trainings) {
    const step = !!matchMedia().xxl
        ? 4
        : !!matchMedia().xl
        ? 3
        : !!matchMedia().lg
        ? 2
        : 1;
    let trainingsCopy = [...trainings];
    /* split initial array in sub-arrays with the length of 4 */
    let result = [];
    while (trainingsCopy.length) {
        result.push(trainingsCopy.splice(0, step));
    }

    return result.map((row, index) => (
        <RowWrapper key={index}>
            <Row>
                {row.map((training) => {
                    return (
                        <Column key={training.id} xs={12} lg={6} xl={4} xxl={3}>
                            <Teaser
                                title={training.title}
                                type={training.type.name}
                                image={training.image}
                                points={training.points}
                                duration={training.duration}
                                completed={training.completed}
                                route={training.route}
                                isReleased={training.isReleased}
                            />
                        </Column>
                    );
                })}
            </Row>
        </RowWrapper>
    ));
}

TrainingShelf.propTypes = {
    trainings: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            type: PropTypes.shape({
                name: PropTypes.string,
            }),
            image: PropTypes.string,
            points: PropTypes.number,
            duration: PropTypes.string,
            completed: PropTypes.bool,
        })
    ),
};

export default TrainingShelf;
