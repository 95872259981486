import styled from 'styled-components';

export const Div = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: ${({alignItems}) => alignItems};
    justify-content: ${({justifyContent}) => justifyContent};
    position: ${({position}) => position};
    top: ${({top}) => top};
    bottom: ${({bottom}) => bottom};
    right: ${({right}) => right};
    display: ${({display}) => display};
    height: ${({height}) => height};
    width: ${({width}) => width};
    max-width: ${({maxWidth}) => maxWidth};
    overflow: ${({overflow}) => overflow};
    margin-top: ${({marginTop}) => marginTop};
`;
