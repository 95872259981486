import React from 'react';

export default {
    // eslint-disable-next-line react/prop-types
    hallo: (gender, name) => (
        <>
            <strong>Guten Tag,</strong>{' '}
            <i>
                {gender} {name}!
            </i>
        </>
    ),
    paragraph1:
        'Willkommen in Deiner Lieblings-Apotique. Hier kannst Du Dir Deine Trainingskollektion nach Deinen Wünschen zusammenstellen. ',
    button: 'Deine Interessen bearbeiten',
    schaufensterHeadline: (
        <i>
            Auf Dich{` `}
            <strong>zugeschnittene Trainings</strong>
        </i>
    ),
    schaufensterParagraph: (
        numTrainingsMatchingInterest,
        numTrainingsNotMatchingInterest
    ) => {
        if (numTrainingsMatchingInterest > 0) {
            return 'Ausgehend von Deinen Interessen und abgeschlossenen Trainings bieten wir Dir folgende Wissenskonfektion:';
        } else if (numTrainingsNotMatchingInterest > 0) {
            return 'Zur Zeit gibt es keine neuen Trainings, die zu Deiner Auswahl passen. Diese Trainings könnten Dich aber auch interessieren.';
        } else {
            // numTrainingsMatchingInterest === 0 && numTrainingsNotMatchingInterest === 0
            return 'Zur Zeit gibt es keine neuen Trainings, die zu Deiner Auswahl passen.';
        }
    },
    regale: (
        <>
            <strong>Stöbere durch</strong> <i> unsere Regale</i>
        </>
    ),
    paragraph3: (
        <>
            Gönn Dir einen ausgiebigen Bummel durch unseren Wissensbestand.
            Neben Produktinformationen findest Du Team&#8209;Challenges und
            Inspirationen für die Innenausstattung Deiner Apotheke.{' '}
        </>
    ),
    headerAlt:
        'Header-Bild: Apothekerin zeigt auf die Apotique-Trainingskollektion und den Punktestand des Users',
    successLightboxButton: 'Zur Trainingskollektion',
};
