import styled from 'styled-components';
import {color} from '../../../../styles/variables';
import {md, smMax} from '../../../../styles/media';

export const TrainingShelfWrapper = styled.div`
    ${smMax`
        .ap-container {
            padding: 0;
        }

        .ap-row {
            margin: 0 0 1rem 0;
        }

        .ap-col {
            padding: 0;
        }
    `}

    ${md`
        .ap-container {
            padding: 2rem;
            background-color: ${color.snow};
        }
        .ap-teaser {
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
        }
    `}
`;

export const RowWrapper = styled.div`
    ${md`
        background-color: ${color.fog};
        margin-top: 2rem;
        border: 10px solid white;
        border-bottom-width: 0;
        border-top-color: #dedede;

        &:first-child {
            margin-top: 0;
        }

        .ap-row {
            border: 3rem solid #ececec;
            border-top-width: 0;
            border-bottom-color: #dedede;
            margin: 0;
        }
    `}
`;
