import styled, {css} from 'styled-components';
import Button from '../../../components/Button';
import {color, font} from '../../../../styles/variables';

export const Wrapper = styled.div`
    background-color: ${color.white};
    ${(props) =>
        props.isShow &&
        css`
            display: none;
        `};
`;

export const Text = styled.span`
    margin: 1rem auto;
    font-family: ${font.type.serif};
    font-weight: ${font.weight.semibold};
`;

export const StyledFilterButton = styled(Button)`
    width: 100%;
    :hover {
        .ap-icon {
            svg {
                fill: ${color.white} !important;
            }
        }
    }
`;

export const FilterButtonWrapper = styled.div``;
