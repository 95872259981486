import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Layout from '../ui/layout/Layout';
import {Section, Container, Column, Row} from '../ui/layout/Grid';
import Header from '../ui/layout/Header';
import TrainingHeaderImage from '../assets/images/trainings/img-hero--trainingskollektion.png';
import Headline from '../ui/components/Headline';
import lang from '../lang/pages/trainings.lang';
import profileLang from '../lang/pages/profil.lang';
import Paragraph from '../ui/components/Paragraph';
import Link from '../ui/components/Link';
import TrainingFilter from '../ui/domains/training/TrainingShelf/TrainingFilter';
import TrainingTeaser from '../ui/domains/training/Teaser';
import TrainingShelf from '../ui/domains/training/TrainingShelf';
import ShopWindow from '../ui/layout/ShopWindow';
import MultiStepPersonalization from '../ui/domains/profile/MultiStepPersonalization';
import {
    selectUser,
    selectUserCategories,
    selectIsPersonalizationFinished,
} from '../model/profile';
import {selectIsLoggedIn} from '../model/authentication';
import {
    selectById as selectTrainingsById,
    selectCategories,
    selectTypes,
    selectBrandsForTrainings,
    suggest as suggestTrainings,
    filter as filterTrainings,
} from '../model/training';
import {routes} from '../model/navigation';
import Divider from '../ui/components/Divider';
import {color} from '../styles/variables';
import {
    ConnectedStickyHighlightsBanner,
    ConnectedStickyInteractiveBanner,
} from '../ui/layout/StickyInteractiveBanner';
import {Div} from '../ui/components/Layout';

const Trainings = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userCategories = useSelector(selectUserCategories);
    const profile = useSelector(selectUser);
    const isPersonalizationFinished = useSelector(
        selectIsPersonalizationFinished
    );
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({
        categories: [],
        types: [],
        brands: [],
    });
    const trainingsById = useSelector(selectTrainingsById);
    const categories = useSelector(selectCategories);
    const types = useSelector(selectTypes);
    const brands = useSelector(selectBrandsForTrainings);
    const options = {
        categories: categories.map((category) => ({
            value: category.id,
            label: category.name,
        })),
        types: types.map((type) => ({
            value: type.id,
            label: type.name,
        })),
        brands: brands.map((brand) => ({
            value: brand.id,
            label: brand.name,
        })),
    };
    const onFilterChange = (selectedValues, {name}) => {
        setSelectedFilterOptions({
            ...selectedFilterOptions,
            [name]: selectedValues.map((selectedValue) => selectedValue.value),
        });
    };
    const {
        numTrainingsMatchingInterest,
        numTrainingsNotMatchingInterest,
        suggestedTrainings,
    } = suggestTrainings(trainingsById, isLoggedIn, userCategories);
    const filteredTrainings = filterTrainings(
        trainingsById,
        selectedFilterOptions.types,
        selectedFilterOptions.brands,
        selectedFilterOptions.categories
    );

    return (
        <Layout>
            <Header img={TrainingHeaderImage} alt={lang.headerAlt} paddingTop>
                {profile && (
                    <Headline type="h1">
                        {lang.hallo(
                            profile.salutation === 'Herr'
                                ? 'lieber'
                                : profile.salutation === 'Frau'
                                ? 'liebe'
                                : 'liebe/r',
                            profile.firstName
                        )}
                    </Headline>
                )}
                <Paragraph>{lang.paragraph1}</Paragraph>
                <Link showAsButton href={`${routes.profil}#personalisierung`}>
                    {lang.button}
                </Link>
            </Header>
            <Section>
                <Container width="wide" className="text-center">
                    <Row>
                        <Column md={8} lg={6} offset={{md: 2, lg: 3}}>
                            {isPersonalizationFinished ? (
                                <>
                                    <Headline type="h3">
                                        {lang.schaufensterHeadline}
                                    </Headline>
                                    <Paragraph size="small">
                                        {lang.schaufensterParagraph(
                                            numTrainingsMatchingInterest,
                                            numTrainingsNotMatchingInterest
                                        )}
                                    </Paragraph>
                                </>
                            ) : (
                                <>
                                    <Headline type="h3">
                                        {profileLang.personalisierung.headline}
                                    </Headline>
                                    <Paragraph size="small">
                                        {profileLang.personalisierung.paragraph}
                                    </Paragraph>
                                </>
                            )}
                        </Column>
                    </Row>
                    <Row>
                        <Column xs={12}>
                            <Divider dashed color={color.lightBlue} />
                        </Column>
                    </Row>
                </Container>
                {isPersonalizationFinished ? (
                    <Container width="wide">
                        <ShopWindow teasers={suggestedTrainings}>
                            {(training) => (
                                <TrainingTeaser
                                    title={training.title}
                                    type={training.type.name}
                                    image={training.image}
                                    points={training.points}
                                    duration={training.duration}
                                    completed={training.completed}
                                    route={training.route}
                                    isReleased={training.isReleased}
                                />
                            )}
                        </ShopWindow>
                    </Container>
                ) : (
                    <MultiStepPersonalization />
                )}
            </Section>
            <Section theme="blue">
                <Container width="narrow" className="text-center">
                    <Headline type="h3">{lang.regale}</Headline>
                    <Paragraph size="small">{lang.paragraph3}</Paragraph>
                </Container>
                <Container width="wide">
                    <TrainingFilter
                        options={options}
                        onChange={onFilterChange}
                    />
                </Container>
                <Container width="xtra-wide">
                    <TrainingShelf trainings={filteredTrainings} />
                </Container>
            </Section>
            <Div
                position="absolute"
                maxWidth="100%"
                top="150px"
                right="0px"
                display="flex"
                alignItems="flex-end">
                <ConnectedStickyHighlightsBanner />
                <ConnectedStickyInteractiveBanner />
            </Div>
        </Layout>
    );
};

export default Trainings;
