import styled, {css} from 'styled-components';

import Headline from '../../components/Headline';
import {color, shadow} from '../../../styles/variables';
import {CloseButton, ModalContainer} from '../../components/Lightbox/styles';

const overlayStyles = css`
    box-shadow: ${shadow.blackLight};
    z-index: 10;
`;

export const ClosedWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3rem;
    background-color: ${color.lightBlue};
    ${overlayStyles};
    .ap-icon {
        background-color: ${color.red};
        padding: 10px;
        flex-grow: 0;
    }
`;

export const OpenButton = styled(CloseButton)`
    position: static;
`;

export const OpenWrapper = styled(ModalContainer)`
    width: ${({width}) => width};
    max-width: 100%;
    ${overlayStyles};
`;

export const ClosedHeadline = styled(Headline)`
    writing-mode: vertical-rl;
    text-align: center;
    margin: 0;
    padding: 3rem;
`;
