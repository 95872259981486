import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Lottie from 'lottie-react';
import {OpenWrapper, ClosedWrapper, ClosedHeadline, OpenButton} from './styles';
import {CloseButton, StyledIcon} from '../../components/Lightbox/styles';
import {ReactComponent as StarsSvg} from '../../../assets/images/trainings/img-overlay--punkte.svg';
import animation from '../../../assets/images/trainings/animation-pta.json';
import {Row, Column} from '../Grid';
import {
    loadBannerContent,
    setIsOpen,
    selectDomain as selectInteractiveBanner,
} from '../../../model/interactive-banner';
import {
    loadBannerContent as loadHighlightsBannerContent,
    setIsOpen as setIsHighlightsOpen,
    selectDomain as selectHighlightsBanner,
} from '../../../model/highlights-banner';
import Headline from '../../components/Headline';
import Link from '../../components/Link';
import {Div} from '../../components/Layout';

export const StickyInteractiveBanner = ({
    bannerContent,
    open,
    onClose,
    onOpen,
    width,
}) => {
    const buttonOne = bannerContent?.buttonOne;
    const buttonTwo = bannerContent?.buttonTwo;
    if (!bannerContent) {
        return null;
    }
    return (
        <>
            {open && (
                <OpenWrapper
                    showPadding={bannerContent.type === 'simple'}
                    width={width}>
                    <CloseButton>
                        <StyledIcon
                            name="close"
                            size="2rem"
                            onClick={onClose}
                        />
                    </CloseButton>
                    <Row>
                        <Column xs={12} className="text-center">
                            {bannerContent.type === 'simple' && <StarsSvg />}
                            <Div
                                maxWidth={
                                    bannerContent.type === 'animated' && '80%'
                                }
                                marginTop={
                                    bannerContent.type === 'animated' && '30px'
                                }>
                                <Headline type="h5" fontWeight="bold">
                                    {bannerContent.openHeadline}
                                </Headline>
                            </Div>
                            {bannerContent.type === 'simple' && buttonOne && (
                                <Link
                                    showAsButton
                                    isInternal
                                    href={buttonOne.href}>
                                    {buttonOne.label}
                                </Link>
                            )}
                        </Column>
                        {bannerContent.type === 'simple' && buttonTwo && (
                            <Column
                                xs={12}
                                className="text-center"
                                style={{paddingTop: 0}}>
                                <Link
                                    showAsButton
                                    isInternal
                                    href={buttonTwo.href}>
                                    {buttonTwo.label}
                                </Link>
                            </Column>
                        )}
                        {bannerContent.type === 'animated' && buttonOne && (
                            <Column
                                xs={12}
                                className="text-center"
                                style={{paddingTop: 0, zIndex: '999'}}>
                                <Link
                                    showAsButton
                                    isInternal
                                    href={buttonOne.href}>
                                    {buttonOne.label}
                                </Link>
                            </Column>
                        )}
                        {bannerContent.type === 'animated' && buttonTwo && (
                            <Column
                                xs={12}
                                className="text-center"
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    zIndex: '999',
                                }}>
                                <Link
                                    showAsButton
                                    isInternal
                                    href={buttonTwo.href}>
                                    {buttonTwo.label}
                                </Link>
                            </Column>
                        )}

                        {bannerContent.type === 'animated' && (
                            <Column xs={12} style={{paddingBottom: 0}}>
                                <Lottie
                                    animationData={animation}
                                    autoPlay
                                    style={{
                                        alignItems: 'flex-end',
                                        display: 'flex',
                                    }}
                                />
                            </Column>
                        )}
                    </Row>
                </OpenWrapper>
            )}
            {!open && (
                <ClosedWrapper>
                    <OpenButton>
                        <StyledIcon
                            name="plusOutline"
                            size="2rem"
                            onClick={onOpen}
                        />
                    </OpenButton>
                    <ClosedHeadline
                        type="h5"
                        fontWeight="bold"
                        hideTopMargin
                        hideBottomMargin>
                        {bannerContent.closedHeadline}
                    </ClosedHeadline>
                </ClosedWrapper>
            )}
        </>
    );
};

StickyInteractiveBanner.propTypes = {
    bannerContent: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    width: PropTypes.string,
};

StickyInteractiveBanner.defaultProps = {
    open: true,
};

export const ConnectedStickyInteractiveBanner = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadBannerContent());
    }, [dispatch]);
    const {content, isOpen} = useSelector(selectInteractiveBanner);
    return (
        <Div position="relative" maxWidth="100%">
            <StickyInteractiveBanner
                width="350px"
                bannerContent={content}
                open={isOpen}
                onClose={() => dispatch(setIsOpen(false))}
                onOpen={() => dispatch(setIsOpen(true))}
            />
        </Div>
    );
};
export const ConnectedStickyHighlightsBanner = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadHighlightsBannerContent());
    }, [dispatch]);
    const {content, isOpen} = useSelector(selectHighlightsBanner);

    return (
        <Div position="relative" maxWidth="100%">
            <StickyInteractiveBanner
                width="360px"
                bannerContent={content}
                open={isOpen}
                onClose={() => dispatch(setIsHighlightsOpen(false))}
                onOpen={() => dispatch(setIsHighlightsOpen(true))}
            />
        </Div>
    );
};

export default ConnectedStickyInteractiveBanner;
