import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {
    Wrapper,
    Text,
    StyledFilterButton,
    FilterButtonWrapper,
} from './TrainingFilter.styles';
import {Select} from '../../../components/Form/Select';
import {Column, Container, Row} from '../../../layout/Grid';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';

const TrainingFilter = ({options, onChange}) => {
    const [isShow, setIsShow] = useState(true);
    return (
        <ResponsiveSwitch>
            {(match) => {
                if (match.xl) {
                    setIsShow(true);
                    return (
                        <Wrapper>
                            <Container width="wide">
                                <Row>
                                    <Text>Trainings filtern</Text>
                                </Row>
                                <Row>
                                    <Column md={4}>
                                        <Select
                                            name="categories"
                                            placeholder="Thema wählen"
                                            multi
                                            options={options.categories}
                                            onChange={onChange}
                                        />
                                    </Column>
                                    <Column md={4}>
                                        <Select
                                            name="types"
                                            placeholder="Trainingstyp"
                                            multi
                                            options={options.types}
                                            onChange={onChange}
                                        />
                                    </Column>
                                    <Column md={4}>
                                        <Select
                                            name="brands"
                                            placeholder="Marke"
                                            multi
                                            options={options.brands}
                                            onChange={onChange}
                                        />
                                    </Column>
                                </Row>
                            </Container>
                        </Wrapper>
                    );
                }
                return (
                    <>
                        <FilterButtonWrapper>
                            <Column>
                                <Row>
                                    <StyledFilterButton
                                        label="Filter"
                                        icon="filter"
                                        iconRight
                                        secondary
                                        onClick={() => setIsShow(!isShow)}
                                    />
                                </Row>
                            </Column>
                        </FilterButtonWrapper>
                        <Wrapper isShow={isShow}>
                            <Container width="wide">
                                <Row>
                                    <Text>Trainings filtern </Text>
                                </Row>
                                <Row>
                                    <Column md={4}>
                                        <Select
                                            name="categories"
                                            placeholder="Thema wählen"
                                            multi
                                            options={options.categories}
                                            onChange={onChange}
                                        />
                                    </Column>
                                    <Column md={4}>
                                        <Select
                                            name="types"
                                            placeholder="Trainingstyp"
                                            multi
                                            options={options.types}
                                            onChange={onChange}
                                        />
                                    </Column>
                                    <Column md={4}>
                                        <Select
                                            name="brands"
                                            placeholder="Marke"
                                            multi
                                            options={options.brands}
                                            onChange={onChange}
                                        />
                                    </Column>
                                </Row>
                            </Container>
                        </Wrapper>
                    </>
                );
            }}
        </ResponsiveSwitch>
    );
};

const optionShape = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
});

TrainingFilter.propTypes = {
    options: PropTypes.shape({
        categories: PropTypes.arrayOf(optionShape),
        types: PropTypes.arrayOf(optionShape),
        brands: PropTypes.arrayOf(optionShape),
    }),
    onChange: PropTypes.func,
};

export default TrainingFilter;
